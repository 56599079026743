<template>
  <AreYouSureModal
    :show-modal="showDeleteAccountModal"
    :message="'Are you sure you want to permanently delete your account?'"
    @accept="deleteUserAccount"
    @cancel="showDeleteAccountModal = false"
  />
  <div class="py-5 px-4 bg-white flex flex-col">
    <p class="text-2xl text-dark-dark-gray font-bold pb-8">Account Management</p>
    <div class="md:flex flex-col items-center">
      <div class="flex flex-col gap-8 md:w-80">
        <div class="flex flex-col gap-2">
          <p class="text-xs uppercase">Email</p>
          <div class="flex flex-col gap-2" v-if="waitingOnEmailVerification">
            <input :class="emailStyling" v-model="this.email" type="text" placeholder="Email" />
            <input v-model="this.verificationCode" type="text" placeholder="Verification Code" />
            <p>Please enter the verification code sent to the new email address</p>
            <button @click="this.verifyCodeForEmailChange">Verify Email</button>
          </div>
          <div class="flex flex-col gap-2" v-else>
            <input
              @input="this.removeErrorMessage"
              :class="emailStyling"
              v-model="this.email"
              type="text"
              placeholder="Email"
            />
            <button @click="this.sendCodeToVerifyEmailChange">Update Email</button>
          </div>
          <p v-if="updateFailureReason === 'AliasExistsException'" class="text-red-500 text-sm">
            This email address is already associated with another account
          </p>
          <p v-else-if="updateFailureReason === 'CodeMismatchException'" class="text-red-500 text-sm">
            Your verification code did not match
          </p>
        </div>
        <div
          @click="showDeleteAccountModal = true"
          class="flex flex-row cursor-pointer justify-between items-center rounded-xl border border-light-light-gray p-2"
        >
          <p>Permanently Delete Account</p>
          <font-awesome-icon :icon="['fas', 'chevron-right']" :color="'#30C3E3'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';

export default {
  name: 'AccountManagementSettings',
  data() {
    return {
      showDeleteAccountModal: false,
      waitingOnEmailVerification: false,
      verificationCode: '',
    };
  },
  components: {
    AreYouSureModal,
  },
  computed: {
    email: {
      get() {
        return this.$store.getters.getEmail;
      },
      set(value) {
        this.$store.commit('setEmail', value);
      },
    },
    updateFailureReason: {
      get() {
        return this.$store.getters.getUpdateFailureReason;
      },
      set(value) {
        this.$store.commit('setUpdateFailureReason', value);
      },
    },
  },
  methods: {
    deleteUserAccount() {
      this.$store.dispatch('deleteUserAccount');
    },
    emailStyling() {
      if (this.updateFailureReason === 'email-already-exists') {
        return 'border-red-500';
      }
      return '';
    },
    sendCodeToVerifyEmailChange() {
      this.verificationCode = '';
      this.$store.dispatch('authStoreModule/updateEmailAddress', this.email);
      this.waitingOnEmailVerification = true;
    },
    verifyCodeForEmailChange() {
      let payload = {
        code: this.verificationCode,
        email: this.email,
      };
      this.$store.dispatch('verifyEmailUpdate', payload);
      this.waitingOnEmailVerification = false;
    },
    removeErrorMessage() {
      this.$store.commit('removeUpdateErrorMessage');
    },
  },
};
</script>
