import client from '@/axios/client';
import { uuid } from 'vue-uuid';
import { event } from 'vue-gtag';

const loggedInUserStoreModule = {
  state: {
    // user profile settings
    firstName: '',
    lastName: '',
    gender: '',
    username: '',
    email: '',
    location: '',
    phone: '',
    bio: '',
    dateOfBirth: null,
    profilePictureFile: null,
    profilePictureUrl: '',
    s3Url: '',
    isResized: false,
    hasNotifications: false,
    userProfileSaveStatus: 'save',
    privacySettingsSaveStatus: 'save',

    /**
     * update failre reasons:
     * AliasExistsException - Email already exists in AWS Cognito
     * CodeMismatchException - Verification code did not match
     * username-already-exists - username already exists in slydepost DB
     * email-already-exists - email already exists in slydepost DB
     */
    updateFailureReason: '',

    // privacy and security settings
    visibility: '',

    // user profile
    isLoadingMoreLoggedInUserPosts: false,
    isLoadingMoreLoggedInUserLikes: false,
    isLoadingMoreLoggedInUserLists: false,
    endOfLoggedInUsersPosts: false,
    endOfLoggedInUsersLikes: false,
    postsLikedByLoggedInUser: [],
    postsByLoggedInUser: [],
    totalPostsLikedByLoggedInUser: 0,
    totalPostsByLoggedInUser: 0,
    totalFollowersByLoggedInUser: 0,
  },
  getters: {
    getEndOfLoggedInUsersPosts(state) {
      return state.endOfLoggedInUsersPosts;
    },
    getIsLoadingMoreLoggedInUserPosts(state) {
      return state.isLoadingMoreLoggedInUserLists;
    },
    getIsLoadingMoreLoggedInUserLikes(state) {
      return state.isLoadingMoreLoggedInUserLikes;
    },
    getIsLoadingMoreLoggedInUserLists(state) {
      return state.isLoadingMoreLoggedInUserLists;
    },
    getLoggedInUserPostsCommentsByPostId: (state) => (id) => {
      if (state.postsByLoggedInUser == null) {
        return null;
      }
      const item = state.postsByLoggedInUser.find((item) => item.id === id);
      return item ? item.comments : null;
    },
    getLoggedInUserLikedPostsCommentsByPostId: (state) => (id) => {
      if (state.postsLikedByLoggedInUser == null) {
        return null;
      }
      const item = state.postsLikedByLoggedInUser.find((item) => item.id === id);
      return item ? item.comments : null;
    },
    getLoggedInUserPostsLikes: (state) => (id) => {
      if (state.postsByLoggedInUser == null) {
        return null;
      }
      const item = state.postsByLoggedInUser.find((item) => item.id === id);
      return item ? item.likes : null;
    },
    getLoggedInUserLikedPostsLikes: (state) => (id) => {
      if (state.postsLikedByLoggedInUser == null) {
        return null;
      }
      const item = state.postsLikedByLoggedInUser.find((item) => item.id === id);
      return item ? item.likes : null;
    },
    getIsResized(state) {
      if (state == null) {
        return false;
      } else {
        return state.isResized;
      }
    },
    getHasNotifications(state) {
      return state.hasNotifications;
    },
    getTotalFollowersByLoggedInUser(state) {
      return state.totalFollowersByLoggedInUser;
    },
    getUpdateFailureReason(state) {
      return state.updateFailureReason;
    },
    getUserProfileSaveStatus(state) {
      return state.userProfileSaveStatus;
    },
    getPrivacySettingsSaveStatus(state) {
      return state.privacySettingsSaveStatus;
    },
    getFirstName(state) {
      return state.firstName;
    },
    getLastName(state) {
      return state.lastName;
    },
    getGender(state) {
      return state.gender;
    },
    getUsername(state) {
      return state.username;
    },
    getEmail(state) {
      return state.email;
    },
    getLocation(state) {
      return state.location;
    },
    getPhone(state) {
      return state.phone;
    },
    getBio(state) {
      return state.bio;
    },
    getDateOfBirth(state) {
      return state.dateOfBirth;
    },
    getVisibility(state) {
      return state.visibility;
    },
    getProfilePicture(state) {
      return state.profilePictureUrl;
    },
    getProfilePictureUrl(state) {
      return state.profilePictureUrl != null && state.profilePictureUrl !== ''
        ? state.profilePictureUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
    getS3Url(state) {
      return state.s3Url;
    },
    getProfilePictureFile(state) {
      return state.profilePictureFile;
    },
    getPostsLikedByLoggedInUser(state) {
      return state.postsLikedByLoggedInUser.map((slydepost) => {
        // Check if there are comments to sort
        if (slydepost.comments) {
          let commentsCopy = [...slydepost.comments];

          // Filter and sort parent comments
          commentsCopy = commentsCopy
            .filter((comment) => !comment.parentId)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

          // Sort child comments for each parent comment
          commentsCopy.forEach((parentComment) => {
            if (parentComment.childComments) {
              parentComment.childComments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            }
          });

          // Return a new object to ensure the state is not mutated
          return {
            ...slydepost,
            comments: commentsCopy,
          };
        }

        // Return the post as is if there are no comments to sort
        return slydepost;
      });
    },
    getTotalPostsLikedByLoggedInUser(state) {
      return state.totalPostsLikedByLoggedInUser;
    },
    getTotalPostsByLoggedInUser(state) {
      return state.totalPostsByLoggedInUser;
    },
  },
  mutations: {
    setEndOfLoggedInUsersPosts(state, end) {
      state.endOfLoggedInUsersPosts = end;
    },
    setIsLoadingMoreLoggedInUserPosts(state, loading) {
      state.isLoadingMoreLoggedInUserPosts = loading;
    },
    setIsLoadingMoreLoggedInUserLikes(state, loading) {
      state.isLoadingMoreLoggedInUserLikes = loading;
    },
    setIsLoadingMoreLoggedInUserLists(state, loading) {
      state.isLoadingMoreLoggedInUserLists = loading;
    },
    deleteSlydepostFromLoggedInUsersLikedPost(state, postId) {
      state.postsLikedByLoggedInUser = state.postsLikedByLoggedInUser.filter((obj) => obj.id !== postId);
      state.totalPostsLikedByLoggedInUser = state.totalPostsLikedByLoggedInUser - 1;
    },
    deleteLikeSlydepostInLoggedInUsersLikedPosts(state, payload) {
      const postId = payload.postId;
      const likeId = payload.id;
      for (let i = 0; i < state.postsLikedByLoggedInUser.length; i++) {
        if (postId === state.postsLikedByLoggedInUser[i].id) {
          for (let j = 0; j < state.postsLikedByLoggedInUser[i].likes.length; j++) {
            if (likeId === state.postsLikedByLoggedInUser[i].likes[j].id) {
              state.postsLikedByLoggedInUser[i].likes.splice(j, 1);
            }
          }
        }
      }
    },
    likeSlydepostInLoggedInUsersLikedPosts(state, payload) {
      const postId = payload.postId;
      for (let i = 0; i < state.postsLikedByLoggedInUser.length; i++) {
        if (postId === state.postsLikedByLoggedInUser[i].id) {
          if (state.postsLikedByLoggedInUser[i].likes == null) {
            state.postsLikedByLoggedInUser[i].likes = [];
          }
          state.postsLikedByLoggedInUser[i].likes.push(payload);
          return;
        }
      }
    },
    deleteLikeReplyInLoggedInUsersLikedPosts(state, payload) {
      const likeId = payload.id;
      const parentId = payload.parentId;
      const commentId = payload.commentId;
      const postId = payload.postId;
      for (let i = 0; i < state.postsLikedByLoggedInUser.length; i++) {
        if (postId === state.postsLikedByLoggedInUser[i].id) {
          for (let j = 0; j < state.postsLikedByLoggedInUser[i].comments.length; j++) {
            if (parentId === state.postsLikedByLoggedInUser[i].comments[j].id) {
              for (let k = 0; k < state.postsLikedByLoggedInUser[i].comments[j].childComments.length; k++) {
                if (state.postsLikedByLoggedInUser[i].comments[j].childComments[k].id === commentId) {
                  for (
                    let l = 0;
                    l < state.postsLikedByLoggedInUser[i].comments[j].childComments[k].likes.length;
                    l++
                  ) {
                    if (likeId === state.postsLikedByLoggedInUser[i].comments[j].childComments[k].likes[l].id) {
                      state.postsLikedByLoggedInUser[i].comments[j].childComments[k].likes.splice(l, 1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    likeCommentReplyInLoggedInUsersLikedPosts(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;
      const likeId = payload.id;
      const userId = payload.userId;
      for (let i = 0; i < state.postsLikedByLoggedInUser.length; i++) {
        if (postId === state.postsLikedByLoggedInUser[i].id) {
          for (let j = 0; j < state.postsLikedByLoggedInUser[i].comments.length; j++) {
            if (parentId === state.postsLikedByLoggedInUser[i].comments[j].id) {
              for (let k = 0; k < state.postsLikedByLoggedInUser[i].comments[j].childComments.length; k++) {
                if (state.postsLikedByLoggedInUser[i].comments[j].childComments[k].id === commentId) {
                  if (state.postsLikedByLoggedInUser[i].comments[j].childComments[k].likes == null) {
                    state.postsLikedByLoggedInUser[i].comments[j].childComments[k].likes = [];
                  }
                  const likePayload = {
                    id: likeId,
                    commentId: commentId,
                    userId: userId,
                  };
                  state.postsLikedByLoggedInUser[i].comments[j].childComments[k].likes.push(likePayload);
                  return;
                }
              }
            }
          }
        }
      }
    },
    removeReplyToCommentInLoggedInUsersLikedPosts(state, payload) {
      const commentId = payload.commentId;
      const parentId = payload.parentId;
      const postId = payload.postId;
      for (let i = 0; i < state.postsLikedByLoggedInUser.length; i++) {
        if (postId === state.postsLikedByLoggedInUser[i].id) {
          for (let j = 0; j < state.postsLikedByLoggedInUser[i].comments.length; j++) {
            if (parentId === state.postsLikedByLoggedInUser[i].comments[j].id) {
              for (let k = 0; k < state.postsLikedByLoggedInUser[i].comments[j].childComments.length; k++) {
                if (state.postsLikedByLoggedInUser[i].comments[j].childComments[k].id === commentId) {
                  state.postsLikedByLoggedInUser[i].comments[j].childComments.splice(k, 1);
                }
              }
            } else if (commentId === state.postsLikedByLoggedInUser[i].comments[j].id) {
              state.postsLikedByLoggedInUser[i].comments.splice(j, 1);
            }
          }
        }
      }
    },
    addReplyToCommentInLoggedInUsersLikedPosts(state, payload) {
      const postId = payload.postId;
      const parentCommentId = payload.parentId;
      for (let i = 0; i < state.postsLikedByLoggedInUser.length; i++) {
        if (postId === state.postsLikedByLoggedInUser[i].id) {
          for (let j = 0; j < state.postsLikedByLoggedInUser[i].comments.length; j++) {
            if (parentCommentId === state.postsLikedByLoggedInUser[i].comments[j].id) {
              if (state.postsLikedByLoggedInUser[i].comments[j].childComments == null) {
                state.postsLikedByLoggedInUser[i].comments[j].childComments = [];
              }
              state.postsLikedByLoggedInUser[i].comments[j].childComments.push(payload);
              // state.postsLikedByLoggedInUser[i].comments.push(payload)
              return;
            }
          }
        }
      }
    },
    addCommentLikeToLoggedInUsersLikedPost(state, payload) {
      const postId = payload.postId;
      const commentId = payload.commentId;
      const likeId = payload.id;
      const userId = payload.userId;
      for (let i = 0; i < state.postsLikedByLoggedInUser.length; i++) {
        if (postId === state.postsLikedByLoggedInUser[i].id) {
          for (let j = 0; j < state.postsLikedByLoggedInUser[i].comments.length; j++) {
            if (commentId === state.postsLikedByLoggedInUser[i].comments[j].id) {
              if (state.postsLikedByLoggedInUser[i].comments[j].likes == null) {
                state.postsLikedByLoggedInUser[i].comments[j].likes = [];
              }
              const likePayload = {
                id: likeId,
                commentId: commentId,
                userId: userId,
              };
              state.postsLikedByLoggedInUser[i].comments[j].likes.push(likePayload);
              return;
            }
          }
        }
      }
    },
    removeCommentLikeFromLoggedInUsersLikedPost(state, payload) {
      const likeId = payload.likeId;
      const commentId = payload.commentId;
      const postId = payload.postId;
      for (let i = 0; i < state.postsLikedByLoggedInUser.length; i++) {
        if (postId === state.postsLikedByLoggedInUser[i].id) {
          for (let j = 0; j < state.postsLikedByLoggedInUser[i].comments.length; j++) {
            if (commentId === state.postsLikedByLoggedInUser[i].comments[j].id) {
              for (let k = 0; k < state.postsLikedByLoggedInUser[i].comments[j].likes.length; k++) {
                if (state.postsLikedByLoggedInUser[i].comments[j].likes[k].id === likeId) {
                  state.postsLikedByLoggedInUser[i].comments[j].likes.splice(k, 1);
                  return;
                }
              }
            }
          }
        }
      }
    },
    removeCommentFromLoggedInUsersLikedPost(state, payload) {
      const commentId = payload.commentId;
      const postId = payload.postId;
      const slydepost = state.postsLikedByLoggedInUser.find((item) => item.id === postId);
      const objWithIdIndex = slydepost.comments.findIndex((obj) => obj.id === commentId);
      if (objWithIdIndex > -1) {
        slydepost.comments.splice(objWithIdIndex, 1);
      }
    },
    addCommentToLoggedInUsersLikedPost(state, payload) {
      const comment = payload.comment;
      const postId = payload.postId;
      const slydepost = state.postsLikedByLoggedInUser.find((item) => item.id === postId);
      if (slydepost.comments == null) {
        slydepost.comments = [];
      }
      slydepost.comments.push(comment);
    },
    setIsResized(state, resized) {
      if (resized == null) {
        state.isResized = false;
      } else {
        state.isResized = resized;
      }
    },
    setHasNotifications(state, has) {
      state.hasNotifications = has;
    },
    removePostFromPublicUserProfile(state, postId) {
      if (state.postsByLoggedInUser == null) {
        return null;
      }
      for (let i = 0; i < state.postsByLoggedInUser.length; i++) {
        if (state.postsByLoggedInUser[i].id === postId) {
          state.postsByLoggedInUser.splice(i, 1);
          return;
        }
      }
    },
    setTotalFollowersByLoggedInUser(state, number) {
      state.totalFollowersByLoggedInUser = number;
    },
    setUpdateFailureReason(state, reason) {
      state.updateFailureReason = reason;
    },
    setUserProfileSaveStatus(state, status) {
      state.userProfileSaveStatus = status;
    },
    setPrivacySettingsSaveStatus(state, status) {
      state.privacySettingsSaveStatus = status;
    },
    setFirstName(state, firstName) {
      state.firstName = firstName;
    },
    setLastName(state, lastName) {
      state.lastName = lastName;
    },
    setGender(state, gender) {
      state.gender = gender;
    },
    setS3Url(state, url) {
      state.s3Url = url;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setLocation(state, location) {
      state.location = location;
    },
    setPhone(state, phone) {
      state.phone = phone;
    },
    setBio(state, bio) {
      state.bio = bio;
    },
    setDateOfBirth(state, dateOfBirth) {
      state.dateOfBirth = dateOfBirth;
    },
    setVisibility(state, visibility) {
      state.visibility = visibility;
    },
    setProfilePictureUrl(state, url) {
      state.profilePictureUrl = url;
    },
    setProfilePictureFile(state, file) {
      state.profilePictureFile = file;
    },
    addToPostsLikedByLoggedInUser(state, payload) {
      if (state.postsLikedByLoggedInUser == null) {
        state.postsLikedByLoggedInUser = [];
      }
      state.postsLikedByLoggedInUser.push(payload);
    },
    clearLoggedInUserState(state) {
      state.firstName = '';
      state.lastName = '';
      state.profilePictureUrl = '';
      state.profilePictureFile = null;
      state.postsLikedByLoggedInUser = [];
      state.postsByLoggedInUser = [];
    },
    addToPostsByLoggedInUser(state, payload) {
      if (state.postsByLoggedInUser == null) {
        state.postsByLoggedInUser = [];
      }
      state.postsByLoggedInUser.push(payload);
    },
    setEndOfLoggedInUsersLikes(state) {
      state.endOfLoggedInUsersLikes = true;
    },
    setTotalPostsLikedByLoggedInUser(state, total) {
      state.totalPostsLikedByLoggedInUser = total;
    },
    setTotalPostsByLoggedInUser(state, total) {
      state.totalPostsByLoggedInUser = total;
    },
    removeUpdateErrorMessage(state) {
      state.updateFailureReason = '';
    },
  },
  actions: {
    getTotalNumberOfFollowersByLoggedInUser(state) {
      let userId = state.getters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      client
        .get(`/relationship/friends/length/${userId}?status=FOLLOWING`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            state.commit('setTotalFollowersByLoggedInUser', response.data.length);
          }
        })
        .catch((error) => {
          console.error('Unable to retrieve total followers', error);
        });
    },
    async getLoggedInUserProfile(state) {
      let userId = state.getters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      client
        .get(`/user/${userId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          state.commit('setFirstName', response.data.firstName);
          state.commit('setLastName', response.data.lastName);
          state.commit('setProfilePictureUrl', response.data.presignedUrl);
          state.commit('setGender', response.data.gender);
          state.commit('setEmail', response.data.email);
          state.commit('setVisibility', response.data.visibility);
          state.commit('setDateOfBirth', new Date(response.data.dateOfBirth));
          state.commit('setBio', response.data.bio);
          state.commit('setLocation', response.data.location);
          state.commit('setPhone', response.data.phone);
          state.commit('setUsername', response.data.username);
          state.commit('setUserProfileSaveStatus', 'waiting');
          state.commit('setPrivacySettingsSaveStatus', 'waiting');
          state.commit('setS3Url', response.data.profilePictureUrl);
          state.commit('setHasNotifications', response.data.hasNotifications);
          state.commit('setIsResized', response.data.resized);
        })
        .catch((error) => {
          console.error('Unable to retrieve user info', error);
        });
    },
    loadMorePostsLikedByLoggedInUser(state, payload) {
      // TODO: need to check why endOfLoggedInUsersLikes is not getting set
      if (state.endOfLoggedInUsersLikes === true) {
        return;
      }
      const userId = state.getters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      const index = payload.index;
      client
        .get(`/post/liked/${userId}?loggedInUserId=${userId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
          params: { index: index },
        })
        .then((response) => {
          if (response.data.length === 0) {
            state.commit('setEndOfLoggedInUsersLikes');
          }
          for (let i = 0; i < response.data.likes.length; i++) {
            state.commit('addToPostsLikedByLoggedInUser', response.data.likes[i]);
          }
          state.commit('setTotalPostsLikedByLoggedInUser', response.data.total);
        })
        .catch((error) => {
          console.error('Unable to retrieve posts', error);
        });
    },
    async updateUserProfile(state, payload) {
      state.commit('setUserProfileSaveStatus', 'inProgress');
      let userId = state.getters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }

      let readyToUploadMedia;
      if (payload.file != null) {
        const photoUrl = URL.createObjectURL(payload.file);
        const image = new Image();
        const imageDimensions = await new Promise((resolve) => {
          image.onload = () => {
            const dimensions = {
              height: image.height,
              width: image.width,
            };
            resolve(dimensions);
          };
          image.src = photoUrl;
        });

        readyToUploadMedia = {
          name: payload.file.name,
          width: imageDimensions.width,
        };
      }

      let firstName = payload.firstName;
      let lastName = payload.lastName;
      let username = payload.username;
      let location = payload.location;
      let bio = payload.bio;
      let email = payload.email;
      let gender = payload.gender;
      let dateOfBirth = payload.dateOfBirth;
      let phone = payload.phone;
      let profilePicture = readyToUploadMedia;
      const jsonBody = JSON.stringify({
        userId,
        firstName,
        lastName,
        username,
        location,
        bio,
        email,
        gender,
        dateOfBirth,
        phone,
        profilePicture,
      });
      // let formData = new FormData();
      // formData.append("jsonPayload", jsonBody)

      client
        .put(`/user/${userId}`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          if (response.data.presignedUrlForUpload != null) {
            const url = response.data.presignedUrlForUpload;
            client
              .put(url, payload.file, {
                headers: {
                  'Content-Type': 'image/jpeg',
                },
              })
              .then((response) => {})
              .catch((response) => {
                console.error(`unable to upload`, response);
              });
          }

          state.commit('setUserProfileSaveStatus', 'done');
          event('user-profile-edited', {
            date: new Date().toUTCString(),
            userId: userId,
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            state.commit('setUpdateFailureReason', error.response.data.reason);
          }
          state.commit('setUserProfileSaveStatus', 'error');
          console.error(`there was an error updating user profile`, error.response);
        });
    },
    updatePrivacySettings(state, payload) {
      state.commit('setPrivacySettingsSaveStatus', 'inProgress');
      const userId = state.getters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      const visibility = payload.visibility;
      const jsonBody = JSON.stringify({
        visibility,
      });
      client
        .put(`/user/settings/privacy/${userId}`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          state.commit('setPrivacySettingsSaveStatus', 'done');
        })
        .catch((error) => {
          state.commit('setPrivacySettingsSaveStatus', 'error');
          console.error('Unable to save privacy settings', error);
        });
    },
    loadMorePostsByLoggedInUser({ dispatch, commit, getters, rootGetters }, payload) {
      if (getters['getIsLoadingMoreLoggedInUserPosts'] === true) {
        return;
      }
      commit('setIsLoadingMoreLoggedInUserPosts', true);
      // TODO: need to check why endOfLoggedInUsersPosts is not getting set
      if (getters['getEndOfLoggedInUsersPosts'] === true) {
        return;
      }
      // const userId = getters['authStoreModule/getUserId'];
      const userId = rootGetters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      let index = payload.index;
      client
        .get(`/post/profile/${userId}?loggedInUserId=${userId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
          params: { index: index },
        })
        .then((response) => {
          if (response.data.length === 0) {
            commit('setEndOfLoggedInUsersPosts', true);
          }
          for (let i = 0; i < response.data.posts.length; i++) {
            commit('addToPostsByLoggedInUser', response.data.posts[i]);
          }
          commit('setTotalPostsByLoggedInUser', response.data.total);
          commit('setIsLoadingMoreLoggedInUserPosts', false);
        })
        .catch((error) => {
          console.error('Unable to retrieve posts', error);
          commit('setIsLoadingMoreLoggedInUserPosts', false);
        });
    },
    deleteUserAccount(state) {
      const userId = state.getters['authStoreModule/getUserId'];
      if (userId == null) {
        return;
      }
      client
        .delete(`/user/${userId}`, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then(async (response) => {
          state.dispatch('authStoreModule/signOut');
        })
        .catch((error) => {
          console.error('Unable to retrieve posts', error);
        });
    },
    async verifyEmailUpdate(state, payload) {
      try {
        // verify email change via verification code
        await state.dispatch('authStoreModule/verifyUpdateEmailAddress', payload.code);
      } catch ({ name, message }) {
        state.commit('setUpdateFailureReason', name);
        console.error(`unable to update email address`, message);
        return;
      }

      // update slydepost DB after cognito has completed the email update
      const userId = state.getters['authStoreModule/getUserId'];
      const email = payload.email;
      const jsonBody = JSON.stringify({
        userId,
        email,
      });
      let formData = new FormData();
      formData.append('jsonPayload', jsonBody);

      client
        .put(`/user/${userId}`, formData, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {
          event('email-address-updated', {
            date: new Date().toUTCString(),
            userId: userId,
          });
        })
        .catch((error) => {
          console.error(`there was an error updating user profile`, error.response);
        });
    },
  },
};

// export the entire module
export { loggedInUserStoreModule };
