<template>
  <div v-show="showModal" class="fixed top-0 bottom-0 left-0 right-0 flex justify-center z-50 bg-modal">
    <div
      class="modal-animation bg-white box-border m-auto absolute bottom-0 w-full rounded-t-[16px] sm:static sm:bottom-unset sm:top-50 sm:w-[580px] sm:rounded-[16px]"
    >
      <div class="p-5">
        <div class="flex justify-end mr-2 cursor-pointer" @click="closeModal">
          <img class="w-[20px] h-[20px]" src="@/assets/close.svg" alt="" />
        </div>
        <p class="text-[16px] text-blue font-[700] mb-3">Edit List</p>
        <div class="mb-2">
          <p class="text-[12px] font-bold uppercase text-[#787979] mb-1">Name of list</p>
          <div v-show="this.validations.showMissingNameError === true">
            <p class="text-red-500">
              {{ this.validations.missingNameErrorMessage }}
            </p>
          </div>
          <input v-model="title" type="text" class="text-[14px] font-bold border-[2px] rounded-sm" />
        </div>

        <div class="mb-2">
          <p class="text-[12px] font-bold uppercase text-[#787979] mb-1">Description</p>
          <div v-show="this.validations.showMissingDescriptionError === true">
            <p class="text-red-500">
              {{ this.validations.missingDescriptionErrorMessage }}
            </p>
          </div>
          <textarea
            v-model="description"
            class="text-[14px] font-bold h-full w-full border-[2px] rounded-sm"
            rows="6"
          ></textarea>
        </div>

        <p class="text-dark-blue text-[16px] mb-2">Select Cover Photo (optional)</p>
        <EditListCoverPhoto @imageSelected="imageSelected" />
      </div>

      <div class="bg-[#E5FAFB] flex flex-wrap items-center p-2 rounded-b-[16px] sm:px-5">
        <div class="w-[50%] flex flex-wrap items-center">
          <div class="w-[80%] pl-1">
            <select
              v-model="status"
              class="border border-light-light-gray bg-white rounded-[10px] text-[18px] sm:text-[20px] font-[400] p-1 sm:p-2"
            >
              <option value="PUBLIC">Public</option>
              <option value="PRIVATE">Private</option>
              <option value="PREMIUM">Premium</option>
            </select>
          </div>
        </div>

        <div class="w-[50%] flex gap-2 items-center justify-end">
          <button
            @click="editListCheck"
            class="px-4 py-2 sm:px-5 sm:py-3 text-[16px] font-[700] cursor-pointer w-[121px] rounded-[12px]"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditListCoverPhoto from '@/components/EditList/EditListCoverPhoto.vue';

export default {
  name: 'EditListModal',
  components: { EditListCoverPhoto },
  data() {
    return {
      showModal: false,
      // name: '',
      // description: '',
      // file: '',
      // status: 'PUBLIC',
      validations: {
        showMissingNameError: false,
        showMissingDescriptionError: false,
        missingNameErrorMessage: 'Please enter a title',
        missingDescriptionErrorMessage: 'Please select a description',
      },
    };
  },
  computed: {
    title: {
      get() {
        return this.$store.getters['editListStoreModule/getTitle'];
      },
      set(title) {
        this.$store.commit('editListStoreModule/setTitle', title);
      },
    },
    description: {
      get() {
        return this.$store.getters['editListStoreModule/getDescription'];
      },
      set(description) {
        this.$store.commit('editListStoreModule/setDescription', description);
      },
    },
    file: {
      get() {
        return this.$store.getters['editListStoreModule/getFile'];
      },
      set(file) {
        this.$store.commit('editListStoreModule/setFile', file);
      },
    },
    existingImageUrl: {
      get() {
        return this.$store.getters['editListStoreModule/getExistingImageUrl'];
      },
      set(url) {},
    },
    status: {
      get() {
        return this.$store.getters['editListStoreModule/getStatus'];
      },
      set(status) {
        this.$store.commit('editListStoreModule/setStatus', status);
      },
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      this.showModal = true;
    },
    imageSelected(e) {
      this.file = e;
    },
    editList() {
      this.$store.dispatch('editListStoreModule/updateListByLoggedInUser');
      this.closeModal();
    },
    editListCheck() {
      if (this.title == null || this.title.trim() === '') {
        this.validations.showMissingNameError = true;
        return;
      }
      this.editList();
    },
  },
  unmounted() {
    this.$store.commit('editListStoreModule/clear');
  },
};
</script>

<style scoped>
.modal-animation {
  animation: scaleUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes scaleUp {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
