import { uuid } from 'vue-uuid';
import client from '@/axios/client';

const singleListStoreModule = {
  namespaced: true,
  state: {
    listId: '',
    userId: '',
    firstName: '',
    lastName: '',
    username: '',
    profilePicture: '',
    userProfilePicturePresignedUrl: '',
    coverPhoto: '',
    title: '',
    description: '',
    updated: '',
    posts: [],
    status: '',
    presignedUrl: '',
    totalPosts: 0,
    follows: [],
    totalFollows: 0,
    isFollowing: false,
    userListFollow: {},
  },
  getters: {
    getUserListFollow(state) {
      return state.userListFollow;
    },
    getListId(state) {
      return state.listId;
    },
    getUserId(state) {
      return state.userId;
    },
    getFirstName(state) {
      return state.firstName;
    },
    getLastName(state) {
      return state.lastName;
    },
    getUsername(state) {
      return state.username;
    },
    getCoverPhoto(state) {
      return state.coverPhoto;
    },
    getProfilePicture(state) {
      return state.profilePicture;
    },
    getTitle(state) {
      return state.title;
    },
    getDescription(state) {
      return state.description;
    },
    getUpdated(state) {
      return state.updated;
    },
    getStatus(state) {
      return state.status;
    },
    getPresignedUrl(state) {
      return state.presignedUrl;
    },
    getUserProfilePicturePresignedUrl(state) {
      return state.userProfilePicturePresignedUrl != null
        ? state.userProfilePicturePresignedUrl
        : 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png';
    },
    getPosts(state) {
      return state.posts != null ? state.posts : [];
    },
    getTotalPosts(state) {
      return state.totalPosts != null ? state.totalPosts : 0;
    },
    getFollows(state) {
      return state.follows != null ? state.follows : [];
    },
    getTotalFollows(state) {
      return state.totalFollows != null ? state.totalFollows : 0;
    },
    getIsFollowing(state) {
      return state.isFollowing;
    },
  },
  mutations: {
    setUserListFollow(state, follow) {
      state.userListFollow = follow;
    },
    setListId(state, id) {
      state.listId = id;
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setFirstName(state, name) {
      state.firstName = name;
    },
    setLastName(state, name) {
      state.lastName = name;
    },
    setUsername(state, name) {
      state.username = name;
    },
    setCoverPhoto(state, photo) {
      state.coverPhoto = photo;
    },
    setProfilePicture(state, picture) {
      state.profilePicture = picture;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setUpdated(state, updated) {
      state.updated = updated;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setPresignedUrl(state, url) {
      state.presignedUrl = url;
    },
    setUserProfilePicturePresignedUrl(state, url) {
      state.userProfilePicturePresignedUrl = url;
    },
    setPosts(state, posts) {
      state.posts = posts;
    },
    setTotalPosts(state, total) {
      state.totalPosts = total;
    },
    setFollows(state, follows) {
      state.follows = follows;
    },
    setTotalFollows(state, total) {
      state.totalFollows = total;
    },
    setIsFollowing(state, following) {
      state.isFollowing = following;
    },
    removePost(state, postId) {
      if (state.posts == null) {
        return;
      }
      for (let i = 0; i < state.posts.length; i++) {
        if (state.posts[i].id === postId) {
          state.posts.splice(i, 1);
          return;
        }
      }
    },
    clear(state) {
      state.listId = '';
      state.firstName = '';
      state.lastName = '';
      state.username = '';
      state.coverPhoto = '';
      state.profilePicture = '';
      state.title = '';
      state.status = '';
      state.presignedUrl = '';
      state.description = '';
      state.updated = '';
      state.posts = [];
      state.totalPosts = 0;
      state.follows = [];
      state.totalFollows = 0;
      state.userProfilePicturePresignedUrl = '';
    },
  },
  actions: {
    async getListById({ dispatch, commit, getters, rootGetters }, listId) {
      commit('clear');
      const loggedInUserId = rootGetters['authStoreModule/getUserId'];
      client
        .get(`/post/list/${listId}?userId=${loggedInUserId}`, {
          withCredentials: true,
          headers: {
            'User-Id': loggedInUserId,
            'request-id': uuid.v1(),
          },
        })
        .then((response) => {
          commit('setUserId', response.data.userId);
          commit('setFirstName', response.data.userFirstName);
          commit('setLastName', response.data.userLastName);
          commit('setUsername', response.data.username);
          commit('setCoverPhoto', response.data.url);
          commit('setProfilePicture', response.data.userProfilePhotoUrl);
          commit('setTitle', response.data.title);
          commit('setDescription', response.data.description);
          commit('setUpdated', response.data.updatedAt);
          commit('setPosts', response.data.posts);
          commit('setTotalPosts', response.data.totalPosts);
          commit('setFollows', response.data.follows);
          commit('setTotalFollows', response.data.totalFollows);
          commit('setIsFollowing', response.data.isFollowing);
          commit('setStatus', response.data.status);
          commit('setListId', response.data.id);
          commit('setPresignedUrl', response.data.presignedUrl);
          commit('setUserProfilePicturePresignedUrl', response.data.userProfilePicturePresignedUrl);
          commit('setUserListFollow', response.data.userListFollow);
        })
        .catch((error) => {
          console.error(`Failed call to get List. Response: ${error.message}`);
        });
    },
    followList({ dispatch, commit, getters, rootGetters }, listId) {
      const jsonBody = JSON.stringify({
        listId: listId,
        userId: rootGetters['authStoreModule/getUserId'],
        userFirstName: rootGetters['authStoreModule/getFirstName'],
        userLastName: rootGetters['authStoreModule/getLastName'],
        username: rootGetters['getUsername'],
        title: getters['getTitle'],
        listOwnerId: getters['getUserId'],
        userProfilePictureUrl: rootGetters['getS3Url'],
        status: 'FOLLOWING',
      });
      client
        .post(`/post/list/follow`, jsonBody, {
          withCredentials: true,
          headers: {
            'User-Id': rootGetters['authStoreModule/getUserId'],
            'request-id': uuid.v1(),
          },
        })
        .then((response) => {
          commit('setUserListFollow', response.data);
        })
        .catch((error) => {
          console.error(`Failed call to get List. Response: ${error.message}`);
        });
    },
    unfollowList({ dispatch, commit, getters, rootGetters }, followId) {
      client
        .delete(`/post/list/unfollow/${followId}`, {
          withCredentials: true,
          headers: {
            'User-Id': rootGetters['authStoreModule/getUserId'],
            'request-id': uuid.v1(),
          },
        })
        .then((response) => {
          commit('setUserListFollow', null);
        })
        .catch((error) => {
          console.error(`Failed call to get List. Response: ${error.message}`);
        });
    },
    deleteList({ dispatch, commit, getters, rootGetters }, listId) {
      client
        .delete(`post/list/${listId}`, {
          withCredentials: true,
          headers: {
            'User-Id': rootGetters['authStoreModule/getUserId'],
            'request-id': uuid.v1(),
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.error(`Failed call to get List. Response: ${error.message}`);
        });
    },
  },
};

// export the entire module
export { singleListStoreModule };
