<template>
  <div>
    <div>
      <p class="text-2xl font-bold text-white">Verify Account</p>
      <p class="text-sm font-medium text-white mt-5">
        Verification code was sent to your email. Please enter the code to verify your account.
      </p>
    </div>
    <div v-if="verifyErrorMessage != null" class="text-red-500 flex flex-row gap-1">
      <span>
        <font-awesome-icon :icon="['fa', 'circle-exclamation']" />
      </span>
      <span>
        <p v-if="verifyErrorMessage === 'expired-code'">
          The code you entered has expired. Please request another code and try again.
        </p>
        <p v-if="verifyErrorMessage === 'code-mismatch'">
          The code you have entered is incorrect. Please enter the correct verification code.
        </p>
      </span>
    </div>
    <div>
      <p class="text-xs font-bold text-white mt-5">RESET CODE</p>
      <input v-model="code" class="mt-3 border-2 border-[#F0F0F0]" placeholder="Enter Code" type="text" />
    </div>
    <div>
      <button
        class="w-full text-base font-bold text-white bg-[#F37653] mt-5 rounded-lg border-0 hover:bg-[#F37653]"
        @click="verify"
      >
        Verify
      </button>
    </div>
    <div class="flex flex-row gap-2 items-center justify-center pt-2">
      <p class="text-center cursor-pointer text-sm text-white" @click="resendCode">Request another code</p>
      <LoaderIcon :show-waiting-icon="false" :status="resendCodeStatus" />
    </div>
    <div>
      <p v-if="resendCodeError === 'too-many-verification-emails-sent'" class="text-red-500">
        Too many verification codes have been sent. Please wait and try again later.
      </p>
    </div>
  </div>
</template>

<script>
import store from '@/store/store';
import client from '@/axios/client';
import { uuid } from 'vue-uuid';
import LoaderIcon from '@/components/Icons/LoaderIcon.vue';
import { mapGetters } from 'vuex';
import router from '@/router';

export default {
  name: 'VerifyAccount',
  components: { LoaderIcon },
  emits: ['cancel', 'signIn'],
  data() {
    return {
      code: '',
    };
  },
  computed: {
    ...mapGetters({
      resendCodeStatus: 'authStoreModule/getResendCodeStatus',
      resendCodeError: 'authStoreModule/getResendCodeError',
      verifyErrorMessage: 'authStoreModule/getVerifyErrorMessage',
    }),
    loginCredentials() {
      return this.$store.getters['authStoreModule/getLoginCredentials'];
    },
    isNewUserRegistered() {
      return this.$store.getters['authStoreModule/getNewUserRegistered'];
    },
  },
  mounted() {
    this.$store.commit('authStoreModule/setResendCodeError', null);
    this.$store.commit('authStoreModule/setVerifyErrorMessage', null);
    this.$store.commit('authStoreModule/setResendCodeStatus', 'waiting');
  },
  methods: {
    async verify() {
      this.$store.commit('authStoreModule/setResendCodeError', null);
      this.$store.commit('authStoreModule/setVerifyErrorMessage', null);
      this.$store.commit('authStoreModule/setResendCodeStatus', 'waiting');
      if (store.state.userEmail == null || store.state.userEmail === '') {
        return;
      }

      const username = store.state.userEmail;
      const code = this.code;
      const jsonBody = JSON.stringify({
        username,
        code,
      });
      this.$store.commit('authStoreModule/setResendCodeStatus', 'inProgress');
      client
        .post(`/verify/`, jsonBody, {
          headers: {
            'request-id': uuid.v1(),
          },
        })
        .then(async (response) => {
          this.$store.commit('authStoreModule/setVerifyErrorMessage', null);
          const payload = this.loginCredentials;
          await this.$store.dispatch('authStoreModule/signin', payload);
          this.$store.commit('authStoreModule/setLoginCredentials', {});
          if (this.isNewUserRegistered) {
            this.$store.commit('authStoreModule/setNewUserRegistered', false);
            router.push('/welcome');
          } else {
            router.push({ name: 'Newsfeed' });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('authStoreModule/setVerifyErrorMessage', error.response.data.message);
        })
        .finally(() => {
          this.$store.commit('authStoreModule/setResendCodeStatus', 'done');
        });
    },
    resendCode() {
      this.$store.commit('authStoreModule/setResendCodeError', null);
      this.$store.commit('authStoreModule/setResendCodeStatus', 'waiting');
      if (store.state.userEmail == null || store.state.userEmail === '') {
        return;
      }
      const email = store.state.userEmail;
      this.$store.dispatch('authStoreModule/resendCode', email);
    },
  },
};
</script>
