<template>
  <div class="flex flex-col bg-white h-[116px] w-full rounded-xl pt-1">
    <div class="flex flex-row gap-4">
      <div class="flex cursor-pointer w-[116px] h-[116px]">
        <div class="relative rounded-[15px] bg-transparent flex items-center justify-center md:justify-center">
          <img class="w-[116px] h-[116px] object-cover object-center rounded-2xl" :src="coverPhoto" />
          <div
            class="absolute top-[10%] right-[-10%] bg-[#E5FAFB] w-10 h-10 font-[700] text-[14px] flex justify-center items-center rounded-full border-4 border-black"
          >
            {{ metaRating }}
          </div>
        </div>
      </div>

      <div class="md:flex md:flex-row md:justify-between items-center md:w-[480px]">
        <div class="flex flex-col justify-center">
          <div class="md:hidden">
            <p class="text-[11px] font-[700] uppercase text-[#565758]">
              {{ category }}
            </p>
            <router-link :to="{ name: 'ViewSlydepost', params: { postId: id } }">
              <p class="text-[16px] font-[700]">{{ title }}</p>
            </router-link>
            <div class="text-blue text-[11px] font-[700] tracking-[0.25px] uppercase">
              {{ formattingAspects }}
            </div>
          </div>
          <div class="hidden md:flex md:flex-col">
            <div class="flex flex-row items-center">
              <p class="text-[11px] font-[700] uppercase text-[#565758]">{{ category }} |&nbsp;</p>
              <div class="text-blue text-[11px] font-[700] tracking-[0.25px] uppercase">
                {{ formattingAspects }}
              </div>
            </div>
            <router-link :to="{ name: 'ViewSlydepost', params: { postId: id } }">
              <p class="text-[16px] font-[700]">{{ title }}</p>
            </router-link>
          </div>
          <div class="md:hidden">
            <div class="text-[11px] font-[500] text-blue tracking-[0.25px] uppercase underline">
              <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
                {{ displayName }}
              </router-link>
            </div>
            <div class="flex flex-row gap-2 font-[500] text-[11px] tracking-[0.25px]">
              <p v-if="address != null">{{ address }}</p>
              <p>{{ formattedDate }}</p>
            </div>
          </div>
          <div class="hidden md:flex md:flex-col">
            <div class="flex flex-row gap-2 font-[500] text-[11px] tracking-[0.25px]">
              <p v-if="address != null">{{ address }}</p>
              <p>{{ formattedDate }}</p>
            </div>
            <div class="text-[11px] font-[500] text-blue tracking-[0.25px] uppercase underline">
              <router-link :to="{ name: 'UserProfile', params: { userId: userId } }">
                {{ displayName }}
              </router-link>
            </div>
          </div>
          <div class="relative md:hidden">
            <font-awesome-icon
              :icon="['fas', 'ellipsis']"
              size="lg"
              color="#606770"
              class="cursor-pointer"
              @click="toggleProfileMenu"
            />
            <DropdownEmitContent
              class="absolute top-6 bg-transparent z-20"
              :active="this.showListOptionsMenu"
              :options="postOptions"
              @removeSlydepost="this.checkRemovePost()"
              v-click-outside="closeOptionsMenu"
            />
          </div>
        </div>
        <div class="hidden md:flex">
          <div class="relative">
            <font-awesome-icon
              :icon="['fas', 'ellipsis']"
              size="lg"
              color="#606770"
              class="cursor-pointer"
              @click="toggleProfileMenu"
            />
            <DropdownEmitContent
              v-if="isOwner"
              class="absolute top-6 bg-transparent z-20"
              :active="this.showListOptionsMenu"
              :options="postOptions"
              @removeSlydepost="this.checkRemovePost()"
            />
          </div>
        </div>
      </div>
    </div>
    <AreYouSureModal
      :showModal="checkRemovePostModal"
      :message="'Are you sure you want to remove this Slydepost from your list?'"
      @accept="this.removeSlydepostFromList()"
      @cancel="checkRemovePostModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import slydepostLogo from '@/assets/no-photo-background-square.png';
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';
import DropdownEmitContent from '@/components/DropdownMenu/DropdownEmitContent.vue';

export default {
  name: 'ListPost',
  components: {
    AreYouSureModal,
    DropdownEmitContent,
  },
  props: {
    showEditListModalFunc: {
      type: Function,
      required: true,
    },
    listId: String,
    lists: Array,
    id: String,
    userId: String,
    title: String,
    category: String,
    username: String,
    firstName: String,
    lastName: String,
    address: String,
    updatedAt: String,
    metaRating: Number,
    media: Array,
    aspects: Array,
    data: {},
    isOwner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showListOptionsMenu: false,
      checkRemovePostModal: false,
      postOptions: [
        {
          text: 'Remove',
          onClickEmit: 'removeSlydepost',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({}),
    displayName() {
      return this.username != null ? this.username : `${this.firstName} ${this.lastName}`;
    },
    coverPhoto() {
      if (this.media != null && this.media.length > 0) {
        let mediaObject = this.media.filter((object) => {
          if (object.order === '0') {
            return object;
          }
        });
        if (mediaObject != null && mediaObject.length > 0) {
          return mediaObject[0].presignedUrl;
        } else {
          return this.media[0].presignedUrl;
        }
      } else {
        return slydepostLogo;
      }
    },
    formattedDate() {
      if (this.updatedAt == null) {
        return new Date().toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      } else {
        return new Date(this.updatedAt).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      }
    },
    formattingAspects() {
      let aspectNames = '';
      for (let i = 0; i < this.aspects.length; i++) {
        aspectNames += this.aspects[i].name + ', ';
      }
      return aspectNames.substring(0, aspectNames.length - 2);
    },
  },
  methods: {
    toggleProfileMenu() {
      this.showListOptionsMenu = !this.showListOptionsMenu;
    },
    closeOptionsMenu() {
      this.showListOptionsMenu = false;
    },
    checkRemovePost() {
      this.showSlydepostOptions = false;
      this.checkRemovePostModal = true;
    },
    removeSlydepostFromList() {
      this.checkRemovePostModal = false;
      const listPost = this.lists.find((e) => e.listId === this.listId);
      this.$store.dispatch('listsStoreModule/removePostsFromList', {
        listId: this.listId,
        listPostIds: [listPost.id],
      });
      this.$store.commit('singleListStoreModule/removePost', this.id);
    },
  },

  mounted() {},
  unmounted() {},
};
</script>
