import { slydepostStoreModule } from '@/modules/SlydepostStoreModule';
import { userStoreModule } from '@/modules/UserStoreModule';
import { loggedInUserStoreModule } from '@/modules/LoggedInUserStoreModule';
import { listsStoreModule } from '@/modules/ListsStoreModule';
import { relationshipStoreModule } from '@/modules/RelationshipStoreModule';
import { searchStoreModule } from '@/modules/SearchStoreModule';
import { authStoreModule } from '@/modules/AuthStoreModule';
import { refinedSearchStoreModule } from '@/modules/RefinedSearchStoreModule';
import { viewSlydepostStoreModule } from '@/modules/ViewSlydepostStoreModule';
import { notificationStoreModule } from '@/modules/NotificationStoreModule';
import { createPostStoreModule } from '@/modules/CreatePostStoreModule';
import { editPostStoreModule } from '@/modules/EditPostStoreModule';
import { singleListStoreModule } from '@/modules/SingleListStoreModule';
import { listHighlightsStoreModule } from '@/modules/ListHighlightsStoreModule';
import { editListStoreModule } from '@/modules/EditListStoreModule';
import { settingsStoreModule } from '@/modules/settings/SettingsStoreModule';
import { exploreStoreModule } from '@/modules/ExploreStoreModule';
import { currentUserStoreModule } from '@/modules/CurrentUserStoreModule';
import { legalStoreModule } from '@/modules/LegalStoreModule';
import { trendingPostStoreModule } from '@/modules/trending/TrendingPostStoreModule';
import { trendingUserStoreModule } from '@/modules/trending/TrendingUserStoreModule';
import { trendingListStoreModule } from '@/modules/trending/TrendingListStoreModule';
import { trendingStoreModule } from '@/modules/trending/TrendingStoreModule';
import { createStore } from 'vuex';
export default createStore({
  modules: {
    slydepostStoreModule,
    userStoreModule,
    loggedInUserStoreModule,
    listsStoreModule,
    relationshipStoreModule,
    searchStoreModule,
    authStoreModule,
    refinedSearchStoreModule,
    viewSlydepostStoreModule,
    notificationStoreModule,
    createPostStoreModule,
    editPostStoreModule,
    singleListStoreModule,
    listHighlightsStoreModule,
    editListStoreModule,
    settingsStoreModule,
    exploreStoreModule,
    currentUserStoreModule,
    legalStoreModule,
    trendingPostStoreModule,
    trendingUserStoreModule,
    trendingListStoreModule,
    trendingStoreModule,
  },
});
