import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Lists from '@/views/Lists.vue';
import SingleList from '@/views/SingleList.vue';
import Register from '@/views/Register.vue';
import VerifyAccount from '@/views/VerifyAccount';
import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword';
import ForgotPasswordSubmit from '@/views/ForgotPasswordSubmit';
import Settings from '@/views/Settings.vue';
import NewSlydepost from '@/views/NewSlydepost.vue';
import EditSlydepost from '@/views/EditSlydepost.vue';
import LoggedInUserProfile from '@/views/Profile/LoggedInUserProfile.vue';
import UserProfile from '@/views/Profile/UserProfile.vue';
import Community from '@/views/Community.vue';
import Legal from '@/views/Legal.vue';
import SlydepostView from '@/views/SlydepostView.vue';
import SignOut from '@/views/SignOut';
import Notifications from '@/views/Notifications.vue';
import Search from '@/views/Search/Search.vue';
import { Auth, Hub } from 'aws-amplify';
import store from '@/store/store';
import Congrats from '@/views/Congrats.vue';
import Welcome from '@/views/Welcome.vue';
import CompleteProfile from '@/views/NewDesigns/CompleteProfile.vue';
import Explore from '@/views/Explore.vue';
import Drafts from '@/views/Drafts.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: async (to, from, next) => {
      let user = store.getters['authStoreModule/getUser'];
      if (!user) {
        return next({
          name: 'Login',
        });
      }
      return next({
        name: 'Newsfeed',
      });
    },
  },
  {
    path: '/trending',
    name: 'Trending',
    component: Explore,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/community',
    name: 'Community',
    component: Community,
    meta: {
      requiresAuth: true,
      title: 'Community',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: LoggedInUserProfile,
    meta: {
      requiresAuth: true,
      title: 'Profile',
    },
  },
  {
    path: '/profile/:userId',
    name: 'UserProfile',
    component: UserProfile,
    beforeEnter: async (to, from, next) => {
      let loggedInUserId = store.getters['authStoreModule/getUserId'];
      if (to.params.userId === loggedInUserId) {
        return next({
          name: 'Profile',
        });
      }
      return next();
    },
    meta: {
      requiresAuth: true,
      title: 'Slydepost',
    },
  },
  {
    path: '/newsfeed',
    name: 'Newsfeed',
    component: Home,
    meta: {
      requiresAuth: true,
      title: 'Slydepost',
    },
  },
  {
    path: '/lists',
    name: 'Lists',
    component: Lists,
    meta: {
      requiresAuth: true,
      title: 'Lists',
    },
  },
  {
    path: '/lists/:listId',
    name: 'SingleList',
    component: SingleList,
    meta: {
      requiresAuth: true,
      title: 'Slydepost',
    },
  },
  {
    path: '/slydepost/new',
    name: 'new-slydepost',
    component: NewSlydepost,
    meta: {
      requiresAuth: true,
      title: 'New Slydepost',
    },
  },
  {
    path: '/slydepost/edit',
    name: 'edit-slydepost',
    component: EditSlydepost,
    meta: {
      requiresAuth: true,
      title: 'Edit Slydepost',
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresSignedOut: true,
      title: 'Register',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresSignedOut: true,
      title: 'Login',
    },
  },
  {
    path: '/verify',
    name: 'VerifyAccount',
    component: VerifyAccount,
    meta: {
      requiresSignedOut: true,
      title: 'Verify',
    },
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresSignedOut: true,
      title: 'Forgot Password',
    },
  },
  {
    path: '/forgotPasswordSubmit',
    name: 'ForgotPasswordSubmit',
    component: ForgotPasswordSubmit,
    meta: {
      requiresSignedOut: true,
      title: 'Forgot Password',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      title: 'Settings',
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: Settings,
    meta: {
      requiresAuth: true,
      title: 'Help',
    },
  },
  {
    path: '/signout',
    name: 'SignOut',
    component: SignOut,
    meta: {
      requiresAuth: true,
      title: 'Sign Out',
    },
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal,
    meta: {
      title: 'Legal',
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      requiresAuth: true,
      title: 'Search',
    },
  },
  {
    path: '/slydepost/:postId',
    name: 'ViewSlydepost',
    component: SlydepostView,
    meta: { requiresAuth: true, title: 'Slydepost' },
  },
  {
    path: '/slydepost/comment/:commentId',
    name: 'ViewSlydepostByCommentId',
    component: SlydepostView,
    meta: { requiresAuth: true, title: 'Slydepost' },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: { requiresAuth: true, title: 'Notifications' },
  },
  {
    path: '/congrats',
    name: 'Congrats',
    component: Congrats,
    meta: { requiresSignedOut: true, title: 'Slydepost' },
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: { requiresAuth: true, hideLayout: true, title: 'Slydepost' },
  },
  {
    path: '/complete/profile',
    name: 'Complete Profile',
    component: CompleteProfile,
    meta: { requiresAuth: true, hideLayout: true, title: 'Slydepost' },
  },
  {
    path: '/drafts',
    name: 'Drafts',
    component: Drafts,
    meta: {
      requiresAuth: true,
      title: 'Drafts',
    },
  },
];

Hub.listen('auth', async (data) => {
  switch (data.payload.event) {
    case 'signIn':
      router.push({ name: 'Home' });
      break;
    case 'signUp':
      router.push({ name: 'Login' });
      break;
    case 'signOut':
      store.commit('clearLoggedInUserState');
      store.commit('signOutSlydeposts');
      await router.push({ name: 'Login' });
      break;
    case 'signIn_failure':
      break;
    case 'signUp_failure':
      break;
    case 'confirmSignUp':
      break;
    case 'completeNewPassword_failure':
      break;
    case 'autoSignIn':
      break;
    case 'autoSignIn_failure':
      break;
    case 'forgotPassword':
      break;
    case 'forgotPassword_failure':
      break;
    case 'forgotPasswordSubmit':
      break;
    case 'forgotPasswordSubmit_failure':
      break;
    case 'tokenRefresh':
      break;
    case 'tokenRefresh_failure':
      break;
    case 'cognitoHostedUI':
      break;
    case 'cognitoHostedUI_failure':
      break;
    case 'customOAuthState':
      break;
    case 'customState_failure':
      break;
    case 'parsingCallbackUrl':
      break;
    case 'userDeleted':
      break;
    case 'configured':
      break;
    default:
  }
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve(async (to, from, next) => {
  // restrict pages if user is not signed in
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let user = store.getters['authStoreModule/getUser'];
    if (!user) {
      return next({
        name: 'Login',
      });
    } else {
      return next();
    }
  }
  // don't allow user to see page if they should be signed out
  // i.e. don't let user get to sign in page if they are already signed in
  if (to.matched.some((record) => record.meta.requiresSignedOut)) {
    let user = store.getters['authStoreModule/getUser'];
    if (!user) {
      return next();
    } else {
      return next({
        name: 'Home',
      });
    }
  }
  return next();
});

const DEFAULT_TITLE = 'Slydepost';
router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
