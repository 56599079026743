<template>
  <div>
    <router-link :to="{ name: 'SingleList', params: { listId: id } }">
      <div
        class="h-48 flex flex-col justify-end items-center bg-no-repeat bg-cover bg-center rounded-xl px-2 overflow-hidden"
        :style="backgroundImage"
      >
        <p class="w-full text-md font-bold text-white text-center truncate">
          {{ title }}
        </p>
        <p v-if="postCount === 1" class="text-sm font-bold text-white pb-4">{{ postCount }} Post</p>
        <p v-else class="text-sm font-bold text-white pb-4">{{ postCount }} Posts</p>
        <img class="h-10 w-10 rounded-xl border-2 border-teal" :src="profilePictureUrl" />
        <div class="flex flex-row gap-2 justify-center items-center pt-4 pb-6">
          <font-awesome-icon :icon="['fas', 'user']" class="text-sm font-bold text-white" />
          <p class="text-sm font-bold text-white">{{ followCount }}</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ListCardV2',
  props: {
    id: String,
    title: String,
    postCount: {
      type: Number,
      default: 0,
    },
    followCount: {
      type: Number,
      default: 0,
    },
    cover: String,
    profilePictureUrl: {
      type: String,
      default: 'https://slydepost-ses-storage.s3.us-west-2.amazonaws.com/avatar.png',
    },
  },
  computed: {
    backgroundImage() {
      if (this.cover != null) {
        return `background-image: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)),url("${this.cover}")`;
      } else {
        return `background-color: #015D79`;
      }
    },
  },
};
</script>
