<template>
  <div class="flex flex-col register-component">
    <div class="bg-[#F0F0F0] rounded-lg">
      <button
        class="bg-transparent text-gray border-0 rounded-lg w-1/2 hover:border-2 hover:border-[#015D79] hover:bg-cyan-700"
        @click="signin"
      >
        LOG IN
      </button>
      <button class="bg-white border-2 border-[#015D79] text-[#015D79] rounded-lg w-1/2 hover:bg-cyan-700">
        REGISTER
      </button>
    </div>
    <div>
      <p class="font-bold text-sm mt-3">Ready for reviews that matter? Register for Slydepost below.</p>
    </div>
    <div>
      <p class="font-bold text-sm text-gray mx-2 mt-5">NAME</p>
      <div class="flex">
        <input v-model="firstName" class="me-3" placeholder="First Name" type="text" />
        <input v-model="lastName" placeholder="Last Name" type="text" />
      </div>
    </div>
    <div>
      <p class="font-bold text-sm text-gray mx-2 mt-5">EMAIL</p>
      <div class="relative w-full">
        <input v-model="email" placeholder="Email Address" type="email" />
        <div class="absolute top-2 right-2">
          <img
            v-show="email.includes('@')"
            alt="..."
            class="h-[24px] w-[24px]"
            src="@/assets/ic_radio_active.png"
          />
        </div>
      </div>
    </div>
    <div>
      <p class="font-bold text-sm text-gray mx-2 mt-5">PASSWORD</p>
      <div class="relative w-full">
        <input
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Password"
          @focus="this.showPasswordChecker = true"
        />
        <div class="absolute top-3 right-2">
          <svg
            v-show="showPassword"
            class="w-5 h-5"
            fill="gray"
            stroke="white"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click="showPassword = false"
          >
            <path
              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <svg
            v-show="!showPassword"
            class="w-5 h-5"
            fill="gray"
            stroke="gray"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click="showPassword = true"
          >
            <path
              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <div>
      <p class="font-bold text-sm text-gray mx-2 mt-5">CONFIRM PASSWORD</p>
      <input v-model="confirmPassword" placeholder="Confirm Password" type="password" />
    </div>
    <div class="p-2">
      <PasswordChecker
        :check-password="this.confirmPassword"
        :password="this.password"
        :showChecker="this.showPasswordChecker"
      />
    </div>
    <div class="dob">
      <p class="font-bold text-sm text-gray mx-2 mt-2">DOB</p>
      <input v-model="dobFromCalender" :max="new Date().toJSON().slice(0, 10)" class="border-0" type="date" />
    </div>
    <div class="mt-5">
      <p class="text-xs font-bold text-[#787979] my-2 mx-1">GENDER</p>
      <div class="grid grid-cols-3 gap-3 genderRadioBtns my-2">
        <div class="col-start-1 col-end-2">
          <input v-model="gender" class="w-5" type="radio" value="FEMALE" />
          <label :class="gender === 'FEMALE' ? 'font-bold' : 'font-medium'" class="text-sm mx-2">Female</label>
        </div>
        <div class="col-start-2 col-end-3">
          <input v-model="gender" class="w-5" type="radio" value="MALE" />
          <label :class="gender === 'MALE' ? 'font-bold' : 'font-medium'" class="text-sm font-bold mx-2">Male</label>
        </div>
        <div class="col-start-3 col-end-4">
          <input v-model="gender" :value="genderValue" class="w-5" type="radio" />
          <label :class="gender === 'OTHER' ? 'font-bold' : 'font-medium'" class="text-sm font-bold mx-2">Other</label>
        </div>
      </div>
      <div v-show="isOtherGenderSelected">
        <Dropdown
          :disabled="false"
          :maxItem="5"
          :options="this.pronounOptions"
          :overwrite-selected-option="pronoun"
          name="pronouns"
          placeholder="Pronouns"
          @selected="setPronoun"
        />
      </div>
    </div>
    <div v-if="this.showSignUpError" class="pt-2 text-red-500 flex flex-row gap-1">
      <span>
        <font-awesome-icon :icon="['fa', 'circle-exclamation']" />
      </span>
      <span>
        <p>{{ this.signUpErrorMessage }}</p>
      </span>
    </div>
    <div class="my-5 w-full pt-5">
      <button class="w-full rounded-lg" @click="signUp">Register</button>
    </div>
    <div class="px-3 my-5 text-sm font-medium text-center text-[#787979]">
      <p>
        By clicking Register you are agreeing to the
        <span @click="navigateToTermsOfService" class="underline cursor-pointer">Terms of Service</span>
        and the
        <span @click="navigateToPrivacyPolicy" class="underline cursor-pointer">Privacy Policy</span>
      </p>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Utility/Dropdown.vue';
import { uuid } from 'vue-uuid';
import client from '@/axios/client';
import PasswordChecker from '@/components/SignInSignUp/PasswordChecker.vue';
import store from '@/store/store';
import { mapGetters } from 'vuex';

export default {
  name: 'SignUp',
  components: {
    PasswordChecker,
    Dropdown,
  },
  emits: ['signIn', 'verify'],
  data() {
    return {
      dobFromCalender: '',
      showPassword: false,
      showPasswordChecker: false,
      userId: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      pronoun: '',
      gender: 'FEMALE',
      selectedPronounIndex: null,
      incorrectCredentials: false,
      dateOfBirth: null,
      birthDateMonth: null,
      birthDateDay: null,
      birthDateYear: null,
      pronounOptions: [
        { name: 'She/her', id: 1, value: 'FEMALE' },
        { name: 'He/him', id: 2, value: 'MALE' },
        { name: 'They/them', id: 3, value: 'THEY' },
        { name: 'Prefer not to say', id: 4, value: 'PREFER_NOT_TO_SAY' },
      ],
      showSignUpError: false,
      signUpErrorMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      socialReferral: 'authStoreModule/getSocialReferral',
      userReferral: 'authStoreModule/getUserReferral',
    }),
    genderValue() {
      if (this.gender === 'THEY') {
        return 'THEY';
      } else if (this.gender === 'PREFER_NOT_TO_SAY') {
        return 'PREFER_NOT_TO_SAY';
      } else {
        return '';
      }
    },
    isOtherGenderSelected() {
      return this.gender != null && this.gender !== 'MALE' && this.gender !== 'FEMALE';
    },
  },
  methods: {
    navigateToTermsOfService() {
      store.commit('legalStoreModule/setPage', 'terms');
      this.$router.push({ name: 'Legal' });
    },
    navigateToPrivacyPolicy() {
      store.commit('legalStoreModule/setPage', 'privacy');
      this.$router.push({ name: 'Legal' });
    },
    isOverEighteen(birthday) {
      let ageDifMs = Date.now() - birthday.getTime();
      let ageDate = new Date(ageDifMs);
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age > 17;
    },
    async signUp() {
      try {
        this.showSignUpError = false;
        /*
         * need to validate the following fields
         * firstName,
         * lastName,
         * email,
         * dateOfBirth,
         * gender
         * password is handled by AWS password policy
         */
        if (this.firstName == null || this.firstName === '') {
          this.showSignUpError = true;
          this.signUpErrorMessage = 'Please enter your first name';
          return;
        }

        if (this.lastName == null || this.lastName === '') {
          this.showSignUpError = true;
          this.signUpErrorMessage = 'Please enter your last name';
          return;
        }

        if (this.email == null || this.email === '') {
          this.showSignUpError = true;
          this.signUpErrorMessage = 'Please enter your email';
          return;
        }

        this.dateOfBirth = new Date(Date.parse(this.dobFromCalender));

        if (!this.isOverEighteen(this.dateOfBirth)) {
          this.showSignUpError = true;
          this.signUpErrorMessage = 'You must be at least 18 years old to sign up';
          return;
        }

        if (this.gender == null || this.gender === '') {
          this.showSignUpError = true;
          this.signUpErrorMessage = 'Please choose a gender';
          return;
        }

        const birthDateYear = this.dateOfBirth.getFullYear();
        const birthDateMonth = this.dateOfBirth.getMonth() + 1; // month starts at 0 so need to add 1 for aws
        const birthDateDay = this.dateOfBirth.getDate();

        // store email so it can be used later for account verification
        store.state.userEmail = this.email;
        // format month for aws to include leading 0 if needed
        let birthMonth = birthDateMonth.toString();
        if (birthDateMonth < 10) {
          birthMonth = '0' + birthMonth;
        }
        // format day for aws to include leading 0 if needed
        let birthDay = birthDateDay.toString();
        if (birthDateDay < 10) {
          birthDay = '0' + birthDay;
        }
        let birthYear = birthDateYear.toString();
        let fullBirthDate = birthYear + '-' + birthMonth + '-' + birthDay;

        if (this.password !== this.confirmPassword) {
          this.showSignUpError = true;
          this.signUpErrorMessage = 'Passwords do not match';
          return;
        }

        if (this.password.length < 8) {
          this.showSignUpError = true;
          this.signUpErrorMessage = 'Password must be at least 8 characters';
          return;
        }

        const { email, lastName, firstName, gender, dateOfBirth, password } = this;
        const username = this.email;
        const cognitoBirthDate = fullBirthDate;
        const jsonBody = JSON.stringify({
          username,
          email,
          password,
          lastName,
          firstName,
          gender,
          dateOfBirth,
          cognitoBirthDate,
        });
        store.commit('authStoreModule/setLoginCredentials', {
          email,
          password,
        });
        const self = this;
        let url = '/createAccount';
        if (this.socialReferral != null && this.socialReferral !== '') {
          url += `?socialReferral=${this.socialReferral}`;
        } else if (this.userReferral != null && this.userReferral !== '') {
          url += `?userReferral=${this.userReferral}`;
        }
        client
          .post(url, jsonBody, {
            headers: {
              'request-id': uuid.v1(),
            },
          })
          .then(function () {
            self.$store.commit('authStoreModule/setNewUserRegistered', true);
            self.$emit('verify');
          })
          .catch((res) => {
            console.error('error during signup 1', res);
            console.error('error during signup 2', res.response);
            if (res.response && res.response.status === 409) {
              this.showSignUpError = true;
              this.signUpErrorMessage = 'An account with this email already exists.';
            } else if (
              res.response.data != null &&
              res.response.data.message != null &&
              res.response.data.message === 'password-policy'
            ) {
              this.showSignUpError = true;
              this.signUpErrorMessage = 'The password requirements were not met.';
            } else {
              this.showSignUpError = true;
              this.signUpErrorMessage = 'An error occurred. Please try again.';
              console.error(res);
            }
          });
      } catch (error) {
        console.error(error.message);
      }
    },
    signin() {
      this.$emit('signIn');
    },
    setPronoun(pronounOption) {
      // this.pronoun = pronounOption.name;
      // this.selectedPronounIndex = pronounOption.id;
      this.gender = pronounOption.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-component {
  .genderRadioBtns {
    div {
      input[type='radio'] {
        outline: none !important;
        height: 24px;
        width: 24px;
        content: url('@/assets/ic_radio_normal.png');
      }

      input[type='radio']:checked {
        content: url('@/assets/checkIcon.png');
        height: 24px;
        width: 24px;
      }
    }
  }

  .dob {
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
      background-image: url('@/assets/Icon.png');
      background-repeat: no-repeat;
      height: 24px;
      width: 24px;
      background-position: left;
      position: absolute;
    }

    input[type='date']::-webkit-datetime-edit-fields-wrapper {
      left: 35px;
      position: relative;
    }
  }
}
</style>
