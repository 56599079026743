import client from '@/axios/client';
import { uuid } from 'vue-uuid';
import { Auth } from 'aws-amplify';

const authStoreModule = {
  namespaced: true,
  state: {
    user: null,
    newUserRegistered: false,
    errorMessage: null,
    loginStatus: 'waiting', // waiting, inProgress, done, error
    resendCodeStatus: '', // waiting, inProgress, done, error
    resendCodeError: '',
    verifyErrorMessage: null,
    loginCredentials: {},
    socialReferral: '',
    userReferral: '',
  },
  getters: {
    getSocialReferral(state) {
      return state.socialReferral;
    },
    getUserReferral(state) {
      return state.userReferral;
    },
    getNewUserRegistered(state) {
      return state.newUserRegistered;
    },
    getVerifyErrorMessage(state) {
      return state.verifyErrorMessage;
    },
    getResendCodeError(state) {
      return state.resendCodeError;
    },
    getResendCodeStatus(state) {
      return state.resendCodeStatus;
    },
    getUser(state) {
      return state.user;
    },
    getUserId(state) {
      if (state.user == null) {
        return null;
      }
      return state.user.userId;
    },
    getFirstName(state) {
      if (state.user == null) {
        return null;
      }
      return state.user.firstName;
    },
    getLastName(state) {
      if (state.user == null) {
        return null;
      }
      return state.user.lastName;
    },
    getErrorMessage(state) {
      return state.errorMessage;
    },
    getLoginStatus(state) {
      return state.loginStatus;
    },
    getLoginCredentials(state) {
      return state.loginCredentials;
    },
  },
  mutations: {
    setSocialReferral(state, referral) {
      state.socialReferral = referral;
    },
    setUserReferral(state, referral) {
      state.userReferral = referral;
    },
    setNewUserRegistered(state, payload) {
      state.newUserRegistered = payload;
    },
    setVerifyErrorMessage(state, error) {
      state.verifyErrorMessage = error;
    },
    setResendCodeError(state, error) {
      state.resendCodeError = error;
    },
    setResendCodeStatus(state, status) {
      state.resendCodeStatus = status;
    },
    setLoginStatus(state, status) {
      state.loginStatus = status;
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
    setUser(state, user) {
      if (user == null) {
        localStorage.setItem('user', null);
        state.user = null;
      } else {
        localStorage.setItem('user', JSON.stringify(user));
        state.user = user;
      }
    },
    initializeUser(state) {
      if (localStorage.getItem('user')) {
        // Replace the state object with the stored item
        state.user = JSON.parse(localStorage.getItem('user'));
      }
    },
    setLoginCredentials(state, payload) {
      state.loginCredentials = payload;
    },
  },
  actions: {
    async signin(state, payload) {
      state.commit('setErrorMessage', null);
      state.commit('setLoginStatus', 'inProgress');
      const email = payload.email;
      const password = payload.password;
      const jsonBody = JSON.stringify({
        email,
        password,
      });
      await client
        .post(`/signin`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
          },
        })
        .then(async (response) => {
          const user = {
            userId: response.data.userId,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
          };
          await state.commit('setUser', user);
          state.commit('setLoginStatus', 'done');
        })
        .catch((error) => {
          console.error(`error when attempting to sign in user`, error.response.data.message);
          state.commit('setLoginStatus', 'error');
          if (error.response.data.message != null) {
            state.commit('setErrorMessage', error.response.data.message);
          }
        });
    },
    signOut({ dispatch, commit, getters, rootGetters }) {
      const userId = getters.getUserId;
      client
        .post(`/signOut`, '', {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then(async (response) => {
          commit('setUser', null);
          // await localStorage.setItem('user', null)
          // state.user = null;
          await Auth.signOut();
        })
        .catch((res) => {
          console.error(res);
        });
    },
    resetPassword({ dispatch, commit, getters, rootGetters }, payload) {
      const oldPassword = payload.oldPassword;
      const newPassword = payload.newPassword;
      const userId = getters.getUserId;
      const jsonBody = JSON.stringify({
        oldPassword,
        newPassword,
      });
      client
        .post(`/password/reset`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {})
        .catch((res) => {
          console.error(res);
        });
    },
    updateEmailAddress({ dispatch, commit, getters, rootGetters }, email) {
      const jsonBody = JSON.stringify({ email });
      const userId = getters.getUserId;
      client
        .put(`/email`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {})
        .catch((res) => {
          console.error(res);
        });
    },
    verifyUpdateEmailAddress({ dispatch, commit, getters, rootGetters }, code) {
      const jsonBody = JSON.stringify({ code });
      const userId = getters.getUserId;
      client
        .put(`/email/verify`, jsonBody, {
          withCredentials: true,
          headers: {
            'request-id': uuid.v1(),
            'User-Id': userId,
          },
        })
        .then((response) => {})
        .catch((res) => {
          console.error(res);
        });
    },
    resendCode({ dispatch, commit, getters, rootGetters }, email) {
      commit('setResendCodeStatus', 'inProgress');
      const jsonBody = JSON.stringify({
        email,
      });
      client
        .post(`/resendCode/`, jsonBody, {
          headers: {
            'request-id': uuid.v1(),
          },
        })
        .then((response) => {
          commit('setResendCodeStatus', 'done');
        })
        .catch((error) => {
          commit('setResendCodeStatus', 'error');
          commit('setResendCodeError', error.response.data.message);
          console.error(`unable to resend verification code ${error.response.data.message}`);
        });
    },
  },
};

// export the entire module
export { authStoreModule };
