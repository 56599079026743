<template>
  <div></div>
</template>

<script>
import { Auth } from 'aws-amplify';
import router from '@/router/index';

export default {
  name: 'SignOut',
  components: {},
  data() {
    return {};
  },
  computed: {},
  async setup() {
    try {
      this.$store.commit('clearLoggedInUserState');
      await Auth.signOut();
      await router.push({ name: 'Login' });
    } catch (error) {
      console.error('error signing out: ', error);
    }
  },
  methods: {},
};
</script>
