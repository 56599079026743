<template>
  <div>
    <div :class="!this.listDescription ? 'mb-0' : 'md:mb-3'">
      <div :style="this.backgroundImage" class="relative bg-center bg-no-repeat bg-cover">
        <div class="cursor-pointer absolute top-2 md:top-3 right-2 w-[22px]">
          <div class="flex flex-row gap-2">
            <div>
              <MoreOptionsButton
                class="text-left text-xl"
                v-if="this.isLoggedInUserListOwner"
                v-click-outside="closeOptions"
                @click="this.showOwnerOptions = !this.showOwnerOptions"
                @edit="openEditListModal"
                @delete="this.checkDeleteModal = true"
                :options="this.listOwnerOptions"
                :showOptions="this.showOwnerOptions"
              />
              <MoreOptionsButton
                class="text-left text-xl"
                v-else
                v-click-outside="closeOptions"
                @click="this.showViewerOptions = !this.showViewerOptions"
                @report="this.showReportModal = true"
                :options="this.listViewerOptions"
                :showOptions="this.showViewerOptions"
              />
            </div>
          </div>
        </div>
        <div class="pt-28">
          <div class="line-clamp-3 text-center text-[36px] font-black leading-[48px] text-white tracking-[1px]">
            {{ listTitle }}
          </div>
        </div>
        <div class="font-[900] text-[12px] tracking-[2px] text-center text-white pt-2">
          by
          <router-link v-if="userId != null && userId !== ''" :to="{ name: 'UserProfile', params: { userId: userId } }">
            <p class="uppercase underline">{{ displayName }}</p>
          </router-link>
        </div>
        <div v-if="!this.isLoggedInUserListOwner" class="flex justify-center items-center pt-2">
          <button @click.prevent="followList" class="uppercase h-9 w-32 text-xs bg-dark-orange border-none">
            {{ followText }}
          </button>
        </div>
        <div class="flex flex-row pt-4 justify-center">
          <div
            @click="this.showFollows()"
            :style="this.followsSelectedStyle()"
            class="w-28 flex flex-col justify-center items-center rounded-t-2xl"
          >
            <p class="font-[500] text-[24px] tracking-[2px] pt-2">
              {{ totalFollows }}
            </p>
            <p class="font-[900] text-[10px] tracking-[2px] uppercase">Follows</p>
            <p class="font-bold text-xl text-transparent">|</p>
          </div>
          <div
            @click="this.listPosts()"
            :style="this.listPostsSelectedStyle()"
            class="w-28 flex flex-col justify-center items-center bg-white rounded-t-2xl gap-0 text-blue"
          >
            <div class="font-[500] text-[24px] tracking-[2px] pt-2">
              {{ totalPosts }}
            </div>
            <div class="font-[900] text-[10px] tracking-[2px] uppercase">Slydeposts</div>
            <div class="font-bold text-xl text-blue">|</div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap bg-white p-5" :class="!this.listDescription ? 'mb-0' : 'md:mb-4'">
        <div class="w-full md:w-[30%] flex flex-wrap">
          <div
            class="pr-2 w-[20%] md:w-full flex items-center md:items-end md:pb-2 justify-end md:justify-start text-right md:text-left"
          >
            <p class="font-black text-[10px] tracking-[2px]">LIST BY</p>
          </div>
          <div class="w-[70%] md:w-full flex items-center md:items-start">
            <div class="">
              <router-link
                v-if="userId != null && userId !== ''"
                :to="{ name: 'UserProfile', params: { userId: userId } }"
              >
                <img :src="userProfileImage" class="w-[38px] h-[38px] rounded-full md:rounded-[14px]" />
              </router-link>
            </div>
            <div class="ml-2 leading-[14px]">
              <router-link
                v-if="userId != null && userId !== ''"
                :to="{ name: 'UserProfile', params: { userId: userId } }"
              >
                <h2 class="uppercase font-[700] text-[16px] text-blue">
                  {{ displayName }}
                </h2>
              </router-link>
              <p class="font-[400] text-[12px] text-dark-dark-gray">Updated {{ formattedDate }}</p>
            </div>
          </div>
        </div>
        <div class="w-full md:w-[70%] pt-2 md:pt-0 text-[14px] font-[500 leading-[24px]">
          <ReadMoreText
            class="md:w-full"
            :text="this.listDescription"
            :thisStyle="'text-align: left;'"
            :readMoreCount="250"
            :showLimitedLines="false"
          />
        </div>
      </div>
    </div>
    <div v-if="view === 'slydeposts'" class="flex flex-col gap-4 px-2">
      <ListPost
        v-for="post in posts"
        :user-id="post.userId"
        :key="post.id"
        :id="post.id"
        :username="post.username"
        :first-name="post.userFirstName"
        :last-name="post.userLastName"
        :address="post.address"
        :title="post.title"
        :category="post.category"
        :meta-rating="post.metaRating"
        :updated-at="post.updatedAt"
        :media="post.media"
        :aspects="post.tags"
        :list-id="this.listId"
        :lists="post.lists"
        :is-owner="isLoggedInUserListOwner"
      />
    </div>
    <div v-else-if="view === 'follows'" class="flex flex-col gap-2">
      <ListFollower
        v-for="follow in follows"
        :user-id="follow.userId"
        :key="follow.id"
        :first-name="follow.userFirstName"
        :last-name="follow.userLastName"
        :profile-picture-url="follow.userProfilePicturePresignedUrl"
      />
    </div>
    <EditListModal ref="editListModal" />
    <AreYouSureModal
      :showModal="checkDeleteModal"
      :message="'Are you sure you want to delete your List?'"
      @accept="this.deleteList()"
      @cancel="checkDeleteModal = false"
    />
  </div>
</template>

<script>
import ListPost from '@/components/ViewLists/ListPost.vue';
import EditListModal from '@/components/Modals/EditListModal.vue';
import { mapGetters } from 'vuex';
import ReadMoreText from '@/components/Utility/ReadMoreText.vue';
import MoreOptionsButton from '@/components/Utility/MoreOptionsButton.vue';
import AreYouSureModal from '@/components/Modals/AreYouSureModal.vue';
import ListFollower from '@/components/ViewLists/ListFollower.vue';

export default {
  name: 'SingleList',
  data() {
    return {
      showOwnerOptions: false,
      showViewerOptions: false,
      view: 'slydeposts',
      slydepostSelected: true,
      followsSelected: false,
      showEditModalStatus: false,
      showEditListModal: false,
      showDeleteModal: false,
      showReportModal: false,
      showSingleListOptions: false,
      showSuccessModalStatus: false,
      checkDeleteModal: false,
      listOwnerOptions: [
        {
          text: 'Edit',
          onClickEmit: 'edit',
        },
        {
          text: 'Delete',
          onClickEmit: 'delete',
        },
      ],
      listViewerOptions: [
        {
          text: 'Report',
          onClickEmit: 'report',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      listId: 'singleListStoreModule/getListId',
      firstName: 'singleListStoreModule/getFirstName',
      lastName: 'singleListStoreModule/getLastName',
      username: 'singleListStoreModule/getUsername',
      userId: 'singleListStoreModule/getUserId',
      profilePicture: 'singleListStoreModule/getProfilePicture',
      listTitle: 'singleListStoreModule/getTitle',
      listDescription: 'singleListStoreModule/getDescription',
      listUpdatedDate: 'singleListStoreModule/getUpdated',
      follows: 'singleListStoreModule/getFollows',
      totalFollows: 'singleListStoreModule/getTotalFollows',
      totalPosts: 'singleListStoreModule/getTotalPosts',
      userProfileImage: 'singleListStoreModule/getUserProfilePicturePresignedUrl',
      posts: 'singleListStoreModule/getPosts',
      loggedInUserId: 'authStoreModule/getUserId',
      isFollowing: 'singleListStoreModule/getIsFollowing',
      status: 'singleListStoreModule/getStatus',
      presignedUrl: 'singleListStoreModule/getPresignedUrl',
    }),
    displayName() {
      return this.username != null ? this.username : `${this.firstName} ${this.lastName}`;
    },
    followText() {
      const userListFollow = this.$store.getters['singleListStoreModule/getUserListFollow'];
      if (userListFollow == null) {
        return 'FOLLOW';
      } else {
        return userListFollow.status;
      }
    },
    isLoggedInUserListOwner() {
      const loggedInUserId = this.$store.getters['authStoreModule/getUserId'];
      return loggedInUserId === this.userId;
    },
    backgroundImage() {
      if (this.presignedUrl) {
        return `background-image: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)),url("${this.presignedUrl}")`;
      } else {
        return `background-color: #015D79`;
      }
    },
    formattedDate() {
      if (this.listUpdatedDate == null) {
        return new Date().toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      } else {
        return new Date(this.listUpdatedDate).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      }
    },
  },
  mounted() {
    const listId = this.$route.params.listId;
    if (listId != null) {
      this.$store.dispatch('singleListStoreModule/getListById', listId);
    }
  },
  components: {
    ListPost,
    EditListModal,
    ReadMoreText,
    // DropdownEmitContent,
    MoreOptionsButton,
    AreYouSureModal,
    ListFollower,
  },
  methods: {
    openEditListModal() {
      this.$store.commit('editListStoreModule/setListId', this.listId);
      this.$store.commit('editListStoreModule/setTitle', this.listTitle);
      this.$store.commit('editListStoreModule/setDescription', this.listDescription);
      this.$store.commit('editListStoreModule/setStatus', this.status);
      this.$store.commit('editListStoreModule/setExistingImageUrl', this.presignedUrl);
      this.$refs.editListModal.openModal();
    },
    closeOptions() {
      this.showViewerOptions = false;
      this.showOwnerOptions = false;
    },
    backgroundGradient() {
      return 'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))';
    },
    listPosts() {
      this.view = 'slydeposts';
      this.slydepostSelected = true;
      this.followsSelected = false;
    },
    showFollows() {
      this.view = 'follows';
      this.slydepostSelected = false;
      this.followsSelected = true;
    },
    listPostsSelectedStyle() {
      if (this.slydepostSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    followsSelectedStyle() {
      if (this.followsSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    showEditModal() {
      this.showEditModalStatus = true;
    },
    closeSingleListOptionsModal() {
      this.showSingleListOptions = false;
    },
    closeSuccessModal() {
      this.showSuccessModalStatus = false;
    },
    handleFileChange() {
      const selectedFile = this.$refs.fileInput.files[0];
      if (selectedFile) {
        // Do some operations to upload selectedFile
      }
    },
    followList() {
      const userListFollow = this.$store.getters['singleListStoreModule/getUserListFollow'];
      if (userListFollow == null) {
        const listId = this.$route.params.listId;
        this.$store.commit('singleListStoreModule/setIsFollowing', true);
        this.$store.dispatch('singleListStoreModule/followList', listId);
      } else {
        this.$store.commit('singleListStoreModule/setIsFollowing', false);
        this.$store.dispatch('singleListStoreModule/unfollowList', userListFollow.id);
      }
    },
    unfollowList() {
      if (this.isFollowing) {
        this.$store.commit('singleListStoreModule/setIsFollowing', false);
        const userListFollow = this.$store.getters['singleListStoreModule/getUserListFollow'];
        this.$store.dispatch('singleListStoreModule/unfollowList', userListFollow.id);
      }
    },
    deleteList() {
      if (!this.isLoggedInUserListOwner) {
        return;
      }
      this.$router.push({ name: 'Lists' });
      const listId = this.$route.params.listId;
      this.$store.dispatch('singleListStoreModule/deleteList', listId);
    },
  },
};
</script>
<style scoped>
#editListDiv::-webkit-scrollbar {
  display: none;
}

#editListDiv {
  scrollbar-width: none;
}

.modal-animation {
  animation: scaleUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes scaleUp {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
